<template>
  <HandleDataForms
    :api-source="mongo"
    :data-source="dashboardDB"
    :title="title"
    :collection="collection"
    template-table="TableFeriasForm"
    template-table-modal="TableFeriasModal"
    :data-excel="dataExcel"
  />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const mongo = computed(() => store.getters.getMongoFeriasURL)
    const collection = computed(() => route.params.slug)
    const title = computed(() => route.params.title)
    const dashboardDB = computed(() => store.getters.getDBDashboardFerias)

    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        tipo_documento: 'Tipo documento',
        numero_documento: 'Número de documento',
        nombre: 'Nombre',
        email: 'E-mail',
        celular: 'Celular',
        region: 'Region',
        comuna: 'Comuna',
        sede: 'Sede',
        createdAt: 'Fecha',
      },
      query: {
        collection: collection.value,
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      collection,
      title,
      dataExcel,
      dashboardDB
    }
  },
}
</script>
